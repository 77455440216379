import { useCallback } from 'react'

const useSuperPixel = (product) => {
  const ContextName = 'checkout'

  const triggerPixel = (userCodes) => {
    userCodes?.map((userCode) => sendPixel(userCode))
  }

  const sendPixel = useCallback(
    async (userCode) => {
      const injector = window['SuperPixelInjector']

      if (!userCode) return

      const eventId = `${new Date().getTime()}-${Math.random().toString(36).slice(2)}`
      const runtimeValues = {
        eventId,
        productId: product.productId,
        phase: 'product_page',
        currency: '',
        value: '',
        productName: product.name,
        transactionItems: [{
          code: product.productId
        }]
      }


      const pixelSetups = await injector.getPixelSetups({
        userCode,
        instanceId: product.productId,
        context: ContextName
      })

      for (const pixelSetup of pixelSetups) {
        const isEnabled = pixelSetup.contextFields.marketplace && pixelSetup.contextFields.marketplace.enabled

        if (isEnabled) {
          await injector.sendPixel({
            context: ContextName,
            instanceId: product.productId,
            runtimeValues,
            pixelSetup,
            phase: 'product_page'
          })
        }
      }
    },
    [product]
  )

  return { triggerPixel }
}

export default useSuperPixel