import React, { useContext } from 'react';
import { accordionTypes } from '../../../constants';
import { useTranslation, i18n } from 'next-i18next';
import { faqItems } from './index';
import { FaqProps } from './types';
import { FAQPage } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import Collapsible from './collapsible';
import Utils from '../../../global/scripts/utils';
import { testVersion } from '../../../utils/testVersion';
import { includeSignupInList } from '../../../utils/includeSignup';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';

const Faq = (props: FaqProps) => {
  const { t } = useTranslation();
  const { items, mainEntity } = faqItems(props);
  const context = useContext(ProfileContext);
  const searchParam = context?.query?.v || ''

  // Organic Squad
  if (testVersion(i18n?.language, searchParam)) {
    const signUpQuestion = {
      title: 'Como faço pra criar um produto?',
      content:
        "Primeiro, você precisa criar seu cadastro grátis, <a href='https://sso.hotmart.com/signup?service=https://hotmart.com/auth/signup-callback&systemOrigin=app-hotmart-auth&client_id=f2d997e6-876e-431e-9c0d-c361b8551763' target='_blank'>clicando aqui</a>. Lá dentro, você tem acesso a mais de 15 formatos pra transformar o que você sabe em um produto digital. Além disso, vai poder acessar também o Hotmart Academy.",
      uuid: Utils.generateUUID()
    };

    includeSignupInList(items, signUpQuestion, 3);
  }

  return (
    <div className="faq-container">
      {mainEntity?.length > 0 && (
        <JsonLd<FAQPage>
          item={{
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: mainEntity
          }}
        />
      )}
      <h2 className="_w-full _mb-4 _text-md-5 _font-weight-bold">{`${t(
        'faq.title_new'
      )}`}</h2>
      <Collapsible
        items={items}
        type={accordionTypes.FAQ}
        className="redesigned-modules"
        productId={props?.profile?.product?.productId}
        environment={props?.environment}
      />
    </div>
  );
};

export default React.memo(Faq);
