import React, {useMemo} from 'react';
import classNames from 'classnames';
import IconsImage from 'components/Images/Icons';

export const renderSalesArguments = (
  showSidebarScrolled: boolean,
  showSalesArguments: boolean,
  salesArguments,
  t: (arg: string) => void
) => {
  const totalSales = salesArguments.length;

  if (salesArguments && salesArguments.length > 0) {
    return (
      <div
        className={classNames(
          `_mb-md-5 sales-argument--${totalSales}`,
          {
            'sales-argument--hidden': !showSalesArguments
          }
        )}
      >
        <div
          className={classNames('', {
            'sales-argument__list--sticky': showSidebarScrolled
          })}
        >
          {salesArguments.map((salesArgument, index) => (
            <div className="_d-flex _align-items-center _mb-4">
              <div className="_d-flex _justify-content-center sales-argument__icon">
                {salesArgument?.image && (
                  <IconsImage
                    src={salesArgument?.image?.src}
                    width={salesArgument?.image?.width}
                    height={14}
                  />
                )}
              </div>
              <div
                key={`argument-${index}`}
                className="_text-md-1 _font-weight _ml-3"
                aria-label={salesArgument?.label}
              >
                {salesArgument?.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default renderSalesArguments;
