import Script from 'next/script';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatahubService from '../../utils/datahub';
import { EVENT_ACTIONS } from '../../utils/datahub.constants';
import PixelUtils from '../../utils/pixel';
import config from '../../../env.config';

const ScriptLoader = (props) => {
  const { producerId, pixel, onLoadSuperPixel, productId, productName } = props;
  const hotAccount = producerId ? `hot(\'account\',\'${producerId}\');` : '';
  let timer = '';

  useEffect(() => {
    const getPixelData = (pixel, timer) => {
      if (typeof window !== 'undefined' && window?.hotPx && pixel) {
        window?.hotPx?.integrator
          ?.injectIntegrationScript(pixel?.stringCode)
          .then((data) => {
            clearInterval(timer);
          });
      }
    };

    timer = setTimeout(() => {
      getPixelData(pixel, timer);
    }, 3500);
  }, []);

  const eventId = `${new Date().getTime()}-${Math.random()
    .toString(36)
    .slice(2)}`;
  const runtimeValues = {
    eventId,
    productId: productId,
    phase: 'product_page',
    currency: '',
    value: '',
    productName: productName,
    transactionItems: [
      {
        code: productId
      }
    ]
  };

  return (
    <Fragment>
      <Script
        strategy="afterInteractive"
        id="OPTIMIZELY_SCRIPT"
        src="https://cdn.optimizely.com/js/25880310534.js"
      />
      <Script strategy="afterInteractive" id="GTM_SCRIPT">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KQFMS3D');`}
      </Script>
      <Script strategy="afterInteractive" id="LAUNCHER">
        {`(function(l,a,u,n,c,h,e,r){l['HotmartLauncherObject']=c;l[c]=l[c]||function(){module = '';(l[c].q=l[c].q||[]).push(arguments)},l[c].l=1*new Date();h=a.createElement(u),e=a.getElementsByTagName(u)[0];h.async=1;h.src=n;e.parentNode.insertBefore(h,e)})(window,document,'script','${config.envConfig.ANALYTICS}/launcher.js','hot');hot('system','display');${hotAccount}`}
      </Script>
      {props?.showClarity && (
        <Script strategy="afterInteractive" id="CLARITY">
          {`(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "hrksk9mcfj");`}
        </Script>
      )}
      <Script
        strategy="afterInteractive"
        id="SUPER_PIXEL"
        src={config.envConfig.SUPER_PIXEL}
        onLoad={onLoadSuperPixel}
        onError={() => {
          DatahubService.sendEvent({
            action: EVENT_ACTIONS.PIXEL_INJECTION_ERROR,
            context: 'checkout',
            eventId: eventId,
            errorTrigger: 'script_load_failed',
            errorType: 'generic_error',
            instanceId: JSON.stringify(productId),
            phase: 'product_page',
            runtimeValues: PixelUtils.objectToArray(runtimeValues),
            userAgent: window.navigator.userAgent
          });
        }}
      />
      {/* <Script strategy="lazyOnload" id="SURVICATE">
        {
          "(function(w) { var s = document.createElement('script');s.src = 'https://survey.survicate.com/workspaces/9cd0d9cb3823f32f6cb3492eb6e7d1e4/web_surveys.js';s.async = true;var e = document.getElementsByTagName('script')[0];e.parentNode.insertBefore(s, e);})(window)"
        }
      </Script> */}
    </Fragment>
  );
};

ScriptLoader.propTypes = {
  producerId: PropTypes.string,
  pixel: PropTypes.object
};

export default React.memo(ScriptLoader);
